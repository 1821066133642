import React from 'react';
import { Rating } from '@thd-olt-component-react/rating';
import { arrayOf, func, shape } from 'prop-types';

export const AttributeRatings = ({ ratings, dispatch }) => {
  const getRatingLabel = (rating) => {
    switch (rating.value) {
    case 1:
      return 'Poor';
    case 2:
      return 'Fair';
    case 3:
      return 'Average';
    case 4:
      return 'Good';
    case 5:
      return 'Excellent';
    default:
      return '';
    }
  };

  const renderRating = (rating, index) => {
    const { name } = rating;

    return (
      <>
        <div key={`rating-label-${index}`} className="sui-grid sui-grid-cols-2 sui-items-center">
          <span className="sui-align-middle">{name}</span>
          <span className="sui-font-bold">
            {getRatingLabel(rating)}
          </span>
        </div>
        <Rating
          key={`rating-control-${index}`}
          interactive
          onClick={(value) => dispatch({ rating, value })}
          orange
          sectionClassName={name !== 'Overall' ? 'dashed' : null}
          stayFilled
          type={name === 'Overall' ? 'stars' : 'dash'}
        />
      </>
    );
  };

  return (
    <section className="attribute-ratings">
      <h4 className="sui-font-bold sui-text-lg sui-mb-2" id="product-ratings">How would you rate this product?</h4>
      <div className="sui-grid sui-grid-cols-2 sui-gap-y-4 sui-mb-8">
        {
          ratings
            .filter((rating) => !rating.service)
            .map(renderRating)
        }
      </div>
      <h4 className="sui-font-bold sui-text-lg sui-mb-2" id="service-ratings">How was your service?</h4>
      <div className="sui-grid sui-grid-cols-2 sui-gap-y-4">
        {
          ratings.filter((rating) => rating.service)
            .map(renderRating)
        }
      </div>
    </section>
  );

};

AttributeRatings.propTypes = {
  ratings: arrayOf(shape({})),
  dispatch: func.isRequired
};

AttributeRatings.defaultProps = {
  ratings: []
};