/* global cookieUtils */
import React, { useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Input } from '@thd-olt-component-react/input';
import { Checkbox } from '@thd-olt-component-react/checkbox';
import { invalidEmailAndDisplayName, validateDisplayName, validateEmail } from '../helper';
import { ExistingUser } from './ExistingUser';

export const UserDetails = ({
  className, displayName, email, onDisplayNameChange, onEmailChange
}) => {
  const [displayNameErrorText, setDisplayNameErrorText] = useState('');
  const [details, setDetails] = useState({
    rememberMe: false,
    existingUser: false
  });

  let CookieUtils = cookieUtils;

  useEffect(() => {
    if (!CookieUtils || !CookieUtils.fed_ReadCookie('rememberMe')) return;

    setDetails({
      existingUser: true,
      rememberMe: CookieUtils.fed_ReadCookie('rememberMe'),
      displayName: CookieUtils.fed_ReadCookie('displayName') ? CookieUtils.fed_ReadCookie('displayName') : '',
      email: CookieUtils.fed_ReadCookie('email') ? CookieUtils.fed_ReadCookie('email') : ''
    });

    onDisplayNameChange(CookieUtils.fed_ReadCookie('displayName'));
    onEmailChange(CookieUtils.fed_ReadCookie('email'));
  }, []);

  const toggleRememberMe = (value) => {
    setDetails({
      rememberMe: value,
      existingUser: value
    });

    if (!CookieUtils) return;

    if (value) {
      CookieUtils.fed_CreateCookie('rememberMe', value, null);
      CookieUtils.fed_CreateCookie('displayName', displayName || '', null);
      CookieUtils.fed_CreateCookie('email', email || '', null);
    } else {
      CookieUtils.fed_EraseCookie('displayName');
      CookieUtils.fed_EraseCookie('email');
      CookieUtils.fed_EraseCookie('rememberMe');
    }
  };

  const handleValidateDisplayName = (text) => {
    const result = validateDisplayName(text);
    if (!result.valid) {
      setDisplayNameErrorText(result.message);
    }
    return result.valid;
  };

  const onNotMeButtonClick = () => {
    setDetails({
      ...details,
      existingUser: false
    });
  };

  return (
    <>
      {details.existingUser && (
        <ExistingUser
          displayName={displayName}
          email={email}
          onNotMeClick={() => onNotMeButtonClick()}
        />
      )}
      {!details.existingUser && (
        <Row>
          <Col xs={6}>
            <Row>
              <Col className="write-review-content__input-fields">
                <span className="write-review-content__input-fields__labels">Your Display Name</span>
                <Input
                  errorText={displayNameErrorText}
                  validation={(newDisplayName) => handleValidateDisplayName(newDisplayName)}
                  onChange={(newDisplayName) => {
                    onDisplayNameChange(newDisplayName);
                    toggleRememberMe(false);
                  }}
                  value={displayName}
                  scrollOnFocus
                />
              </Col>
            </Row>
            <span className="write-review-content__input-fields__instruction">
              Do not use your full name. Use letters and numbers only (no space or symbols).
            </span>
          </Col>
          <Col xs={6}>
            <Row>
              <Col className="write-review-content__input-fields">
                <span className="write-review-content__input-fields__labels">Your Email Address</span>
                <Input
                  errorText="Please enter a valid email."
                  onChange={(newEmail) => {
                    onEmailChange(newEmail);
                    toggleRememberMe(false);
                  }}
                  validation={(newEmail) => validateEmail(newEmail)}
                  value={email}
                  scrollOnFocus
                />
              </Col>
            </Row>
            <span className="write-review-content__input-fields__instruction">
              Your email address will not be displayed publicly.
              Emails are used for notification purposes only
            </span>
          </Col>
        </Row>
      )}

      <Checkbox
        className={className}
        value="RememberMe"
        id="RememberMe"
        disabled={invalidEmailAndDisplayName({ email, displayName })}
        checked={details.rememberMe}
        onChange={() => toggleRememberMe(!details.rememberMe)}
      >
        Remember me on this device.
      </Checkbox>
    </>
  );
};

UserDetails.displayName = 'WriteAReviewUserDetails';

UserDetails.propTypes = {
  className: string,
  displayName: string,
  email: string,
  onDisplayNameChange: func.isRequired,
  onEmailChange: func.isRequired
};

UserDetails.defaultProps = {
  className: null,
  displayName: '',
  email: ''
};
