import React, { useEffect, useReducer } from 'react';
import classNames from 'classnames';
import { func, oneOf, shape, string } from 'prop-types';
import { Toggle } from '@one-thd/sui-atomic-components';
import { Col, Image, Row } from '@thd-olt-component-react/core-ui';

import { AttributeRatings } from './AttributeRatings';
import { usePresentation } from '../../../../context/PresentationProvider';

/**
 * This component represents the Top portion of the write a review modal.
 * The rating options represent the quality ratings the user can rate the product on.
 * These options can be any of the following when submitting to BV:
 *      rating: String! // This is "Overall"
 *      rating_appearance: String
 *      rating_easeofinstallation: String
 *      rating_easeofuse: String
 *      rating_energyefficiency: String
 *      rating_features: String
 *      rating_quality: String
 *      rating_value: String
 */
const initialState = {
  recommends: true,
  ratingOptions: [
    { key: 'rating', name: 'Overall', value: null, service: false },
    { key: 'quality', name: 'Quality', value: null, service: false },
    { key: 'value', name: 'Value', value: null, service: false },
    { key: 'delivery', name: 'Delivery', value: null, service: true },
    { key: 'customerservice', name: 'Customer Service', value: null, service: true }
  ]
};

function stateReducer(state, { rating, value }) {
  if (rating === 'recommends') {
    return { ...state, recommends: value };
  }
  const { ratingOptions } = state;
  const name = rating.name ? rating.name : rating;
  const optionIndex = ratingOptions.findIndex((option) => option.name === name);
  if (optionIndex === -1) {
    // If option does not exists, the value SHOULD be the object representing the option so just push
    ratingOptions.push(value);
  } else {
    ratingOptions[optionIndex].value = value.name === name ? null : value;
  }
  return { ...state, ratingOptions };

}

// TODO: Rescope classes
export const ProductRatingDetails = ({
  brandName, className, error, imageUrl, productLabel, onRatingChange, onRecommendationChange, reviewStatistics
}) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const { useCondensedLayout } = usePresentation();
  useEffect(() => {
    if (reviewStatistics) {
      (reviewStatistics.secondaryRatingsLabels || []).forEach((label) => {
        const option = {
          key: label.toLowerCase(),
          name: label,
          value: null,
          service: false
        };
        dispatch({ rating: label, value: option });
      });
    }
  }, []);
  useEffect(() => {
    if (onRatingChange) {
      const { ratingOptions } = state;
      onRatingChange(ratingOptions);
    }
  }, [state.ratingOptions]);
  useEffect(() => {
    if (onRecommendationChange) {
      onRecommendationChange(state.recommends);
    }
  }, [state.recommends]);
  const productInfoClasses = classNames('product-rating-details__product-info', {
    'product-rating-details__product-info--mobile': useCondensedLayout
  });
  const brandClasses = classNames('product-rating-details__brand', {
    'product-rating-details__brand--mobile': useCondensedLayout
  });
  const productLabelClasses = classNames('product-rating-details__product-label', {
    'product-rating-details__product-label--mobile': useCondensedLayout
  });
  const radioClasses = classNames(className, 'radio-btn__content-wrapper');
  const imageSize = useCondensedLayout ? '100' : '300';
  const productDetails = (
    <div className={productInfoClasses}>
      <h1 className={brandClasses}>
        {brandName}
      </h1>
      <p className={productLabelClasses}>
        {productLabel}
      </p>
    </div>
  );
  const Tag = useCondensedLayout ? Row : Col;

  return (
    <Row className="product-rating-details">
      <Col xs={useCondensedLayout ? '3' : '4'} fallback={useCondensedLayout ? '3' : '4'} className={className}>
        <div className="product-rating-details__product-image">
          <Image
            src={imageUrl}
            alt={productLabel}
            width={imageSize}
            lazy
          />
        </div>
      </Col>
      {useCondensedLayout
        && (
          <Col xs="9" fallback="9" className={className}>
            {productDetails}
          </Col>
        )}
      <Tag className={className} fallback="8" md="8">
        {!useCondensedLayout && productDetails}
        {error && error.heading
          && (
            <div className="product-rating-details__errors">
              <h3>{error.heading}</h3>
              <p>{error.message}</p>
            </div>
          )}
        <Col xs="8" className={className}>
          <AttributeRatings ratings={state.ratingOptions} dispatch={dispatch} />
          <div className="sui-grid sui-grid-cols-2 sui-items-center sui-mt-5">
            <span className="sui-font-bold">Do you recommend this product?</span>
            <span>
              <Toggle
                checked={state.recommends === true}
                onChange={() => dispatch({ rating: 'recommends', value: !state.recommends })}
                inputAttributes={{ 'data-testid': 'recommendProductToggle' }}
              />
            </span>
          </div>
        </Col>
      </Tag>
    </Row>
  );

};

ProductRatingDetails.displayName = 'WriteAReviewProductRatingDetails';

ProductRatingDetails.propTypes = {
  brandName: string.isRequired,
  className: string.isRequired,
  error: shape({
    heading: string,
    message: string
  }),
  imageUrl: string.isRequired,
  onRatingChange: func.isRequired,
  onRecommendationChange: func.isRequired,
  productLabel: string.isRequired,
  reviewStatistics: shape({})
};

ProductRatingDetails.defaultProps = {
  error: null,
  reviewStatistics: null
};
